@import '../../styles/1.settings/settings';
@import '../../styles/5.trumps/classes';

.product-list {
  &__container {
    list-style: none;
    padding: 0 $layout-padding;
    border-top: $border;
  }

  &__searchbar {
    width: calc(100% - #{$layout-padding}* 2);
    padding-left: 1.5rem;
    background-color: $color-secondary;
    border-radius: $border-radius;
    position: relative;
    margin: 0 $layout-padding;

    .item-inner {
      background-color: $color-secondary;
      border-radius: $border-radius;

      .item-input-wrap {
        width: 100%;
      }
    }

    .input-primary input {
      width: 100%;
      background-color: $color-secondary;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      background-image: url(../../assets/vectors/mag-glass.svg);
    }
  }

  @media screen and (min-width: $breakpoint-laptop-size) {
    &__container {
      border-top: none;
    }
  }

  &__header {
    @extend %list-header--desktop;
    p {
      padding-left: 2rem;
    }
  }
}
