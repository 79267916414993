@import '../../styles/1.settings/_settings.scss';
@import '../../styles/2.tools/_tools.scss';

.cart-feedback {
  &__illustration {
    margin: $layout-padding;
  }

  .header {
    text-align: center;
    &__content {
      max-width: 200px;
      margin: 8px auto;
    }
  }
  @media screen and (min-width: $breakpoint-laptop-size){
    &.page {
      width: 100vw;
      height: 100vh;
      background-color: rgba(130, 130, 130, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      .page-content {
        min-width: 650px;
        width: 50vw;
        height: 500px;
        background-color: $color-primary;
        padding: 1rem 6rem;
        .navbar {
          display: none;
        }
        .header__content {
          max-width: none;
          margin-top: 1rem;
        }
        .content {
          min-height: auto;
          padding: 0;
        }
        .footer {
          position: relative;
        }
      }
    }
  }
}
