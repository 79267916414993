@import '../../styles/1.settings/_settings.scss';
.navbar {
  $parent: &;
  display: flex;
  padding: $layout-padding $layout-padding 0;
  min-height: calc(20px + #{$layout-padding});
  position: relative;
  a.link.navbar__close-modal {
    width: 24px;
    min-width: auto !important;
    position: absolute;
    top: 36px;
    right: 36px;
  }
  > div {
    display: inline-block;
    align-self: flex-start;
    &:first-of-type {
      margin: auto;
      height: 100%;
      width: 15%;
      text-align: left;
      margin-left: 0;
    }
    &:nth-child(2) {
      width: 70%;
      text-align: center;
    }
    &:last-child {
      margin: auto;
      height: 100%;
      width: 15%;
      text-align: right;
      margin-right: 0;
    }
  }
  &__title {
    text-align: center;
    color: $color-primary;
    font-family: $font-family-thin;
    font-size: $font-size-regular;
    line-height: $font-size-regular;
    text-align: center;
  }
  &__left {
    margin-top: -3px !important;

    a.link {
      height: auto !important;
      padding: 0 !important;
    }
  }
  &:before,
  &:after {
    content: none !important;
  }

  &--blue {
    background-color: $color-primary !important;

    #{$parent}__title {
      color: $color-secondary !important;
    }

    #{$parent}__left__arrow path {
      stroke: $color-secondary;
    }
  }

  &:not(#{&}--blue) {
    min-height: calc(#{$font-size-regular} + #{$layout-padding} * 2);
  }
}

.device-cordova {
  .navbar {
    padding-top: 40px;
  }
}

.app-layout {
  .navbar {
    transform: none;
    position: initial;
  }
}
