@import '../../styles/1.settings/_settings.scss';

.cart-pivots-page {
  &__body {
    padding: 0 $layout-padding;

    &__title {
      margin-bottom: 24px;
    }
  }
}
