@media (min-width: $breakpoint-laptop-size) {
  .welcome-layout {
    display: flex;
    background-color: $color-primary;
    zoom: 0.8;
    &:before {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url('../../assets/vectors/welcome-background.svg');
      background-size: cover;
      opacity: 0.1;
    }
    .navbar {
      max-width: $default-max-width;
      margin: 0 auto;
      padding: $layout-padding 0;
    }
    .navbar__title {
      display: none;
    }
    .page-content {
      display: flex;
      flex-direction: column;
      margin: auto;
      border-radius: $internal-padding / 2;
      background-color: $color-background;
      width: 100%;
      max-width: $welcome-layout-width;
      height: 100%;
      min-height: auto;
      max-height: $welcome-layout-height;
      overflow: hidden;
    }
    .header {
      max-width: $default-max-width;
      text-align: center;
    }
    .content {
      padding-bottom: 0;
      padding-top: 0;
      min-height: auto;
    }
    .footer {
      display: flex;
      position: relative;
      align-items: center;
      padding-top: $internal-padding / 2;
      max-width: $default-max-width;
      margin: auto;

      .button-secondary {
        order: 1;
      }
      .button-primary {
        order: 2;
      }

      .button-primary,
      .button-secondary {
        width: 100%;
        margin-top: $internal-padding;
      }
    }
  }
}
