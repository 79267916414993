@import '../../../../styles/1.settings/settings';
@import '../../../../styles/5.trumps/classes';

.page-farm-pivot {
  position: relative;
  &--name,
  &--aplication-date{
    color: $color-grey-one;
    margin: 0;
  }

  &--name {
    font-size: $font-size-bold;
    line-height: $line-height-bold;
    margin-bottom: 0.68rem;
  }

  &--aplication-date {
    font-family: $font-family-regular;
    font-size: $font-size-thin;
    line-height: $line-height-thin;
    margin-bottom: 1.25rem;
  }

  &--aplication-date{
    color: $color-error;
  }
  &--cancel-icon {
    width: 1.2rem;
  } 
    
  @media screen and (min-width: $breakpoint-laptop-size) {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    &--name {
      font-size: $font-size-bold;
      line-height: $line-height-bold;
    }
    &--aplication-date{
      font-size: $font-size-semibold;
      line-height: $line-height-semibold;
    }
    
    &--name, &--aplication-date {
      margin: 0;
      width: 45%;
    }
    &--cancel {
      margin-left: auto;
    }
  }
}