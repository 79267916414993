@import '../../styles/1.settings/settings';
@import '../../styles/5.trumps/classes';

.farm-list-item {
  &__text-container{
    &--name {
      margin-bottom: 0.5rem;
    }
    &--pivots {
      font-family: $font-family-regular;
      font-size: $font-size-thin;
      line-height: $line-height-thin;
      color: $color-link;
      margin: 0;
    }
  }
  &__arrow {
    transform: rotateY(180deg);
  }

  @media screen and (min-width: $breakpoint-laptop-size) {
    &__text-container {
      display: flex;
      align-items: center;
      flex: 1 0 auto;
      &--name {
        margin-bottom: 0;
        width: 50%;
      }
      &--pivots {
        font-size: $font-size-bold;
        line-height: $line-height-bold;
        color: $color-grey-one;
        margin: 0;
        width: 50%;
      }
    }
  }
}