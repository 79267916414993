@import '../../../styles/1.settings/_settings.scss';
.config-product-item-title {
  width: 64%;
  &--name{
    color: $color-text-main;
  }
  &--description {
    margin-top: 4px;
  }
  &--quantity {
    color: $color-primary;
  }
}