@import '../../styles/1.settings/settings';
@import '../../styles/5.trumps/classes';

.product-list__item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: $internal-padding 0;

  &__name {
    font-family: $font-family-bold;
    font-size: $font-size-bold;
    line-height: $line-height-bold;
    color: $color-text-main;
    margin: auto 0;
  }

  &__stock {
    font-family: $font-family-regular;
    font-size: $font-size-thin;
    line-height: $line-height-thin;
    color: $color-link;
    margin: 4px 0 0;
  }

  &:not(:last-of-type) {
    border-bottom: $border;
  }

  &__action {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    width: auto;
    cursor: pointer;
  }

  @media screen and (min-width: $breakpoint-laptop-size) {
    @extend %list-item--desktop;
    padding: 1.25rem 3rem;
    cursor: pointer;
  }
}
