@import '../../styles/1.settings/_settings.scss';

li.input-primary {
  --f7-list-item-padding-horizontal: 0px;
  --f7-input-outline-border-radius: #{$border-radius};
  --f7-input-outline-padding-horizontal: 16px;
  --f7-input-outline-border: #{$border};
  --f7-input-outline-border-color: rgba(#{$color-grey-two}, 0.25);
  --f7-input-bg-color: #{$color-secondary};
  --f7-list-item-padding-vertical: 0px;
  --f7-input-height: 3rem;
  list-style: none;
  margin: $input-margin;
  max-width: $default-max-width;

  input {
    height: 3rem !important;

    font-family: $font-family-thin !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: rgba($color-grey-one, 0.5) !important;
  }

  .item-input-focused .item-input-wrap:after {
    border: $border-focus !important;
  }

  .item-input-wrap:after {
    border: $border !important;
  }

  .item-label {
    text-align: left;
    left: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &--has-error {
    .item-input-focused .item-input-wrap:after {
      border: $border-error !important;
    }

    .item-input-wrap:after {
      border: $border-error !important;
    }
  }

  @media screen and (min-width: $breakpoint-laptop-size) {
    max-width: none;
  }
}
@media screen and (min-width: $breakpoint-laptop-size) {
  .toolbar-inner > .calendar-month-selector,
  .toolbar-inner > .calendar-year-selector {
    width: 100%;
    max-width: none;
    > a.icon-only {
      flex-shrink: 1 !important;
    }
  }
}

.primary-input {
  &__container {
    &__input-mask {
      position: relative;

      .item-input-wrap {
        input {
          padding: 0 45px 0 16px !important;
        }
      }

      .eye-password-button {
        width: auto;
        cursor: pointer;
        border: none;
        background-color: transparent;
        position: absolute;
        top: 50%;
        right: 16px;
        min-height: 24px;
      }

      .primary-input {
        padding-right: 45px;
      }
    }
  }
}
