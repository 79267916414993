@import '../../styles/1.settings/settings';

.container-header {
  list-style: none;
  display: flex;
  width: fit-content;
  padding-left: $layout-padding;
  border-bottom: $border;
  .container-header__item {
    display: inline-flex;
    white-space: nowrap;
    position: relative;
    padding: 0 1rem 0.8rem;
    margin-right: 2rem;
    cursor: pointer;

    &--selected::after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 2px;
      background-color: $color-primary;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  @media screen and (min-width: $breakpoint-laptop-size) {
    width: 100%;
  }
}
