@import '../../styles/1.settings/_settings.scss';

.default-header {
  padding-bottom: $layout-padding;
  margin-bottom: 1.5rem;
  background-color: $color-primary;

  &__title {
    color: $color-secondary;
  }

  &__title {
    padding: 1.5rem $layout-padding 0;
  }
  @media screen and (min-width: $breakpoint-laptop-size) {
    display: none;
  }
}
