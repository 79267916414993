@import '../../styles/1.settings/settings';

/* Don't try this at home, I am a professional JavaScript developer */

.slider {
  width: 100%;

  .rc-slider-rail {
    height: 4px !important;
    border-radius: 20px !important;
    background-color: rgba($color-grey-two, 0.25) !important;
  }

  .rc-slider-track {
    height: 4px !important;
    border-radius: 20px !important;
    background-color: $color-primary !important;
  }

  .rc-slider-handle {
    width: 16px !important;
    height: 16px !important;
    background-color: $color-primary !important;
    border: none !important;

    &::after {
      content: '';
      display: inline-block !important;
      width: 32px !important;
      height: 32px !important;
      position: absolute !important;
      top: -50% !important;
      left: -50% !important;
      background-color: rgba($color-primary, 0.2) !important;
      border-radius: 50% !important;
    }
  }
}
