@import '../../../styles/1.settings/settings';
@import '../../../styles/5.trumps/classes';

.order-table-item {
  border: 1px solid rgba(130, 130, 130, 0.25);
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  margin-bottom: 1rem;
  padding: $internal-padding;
  &__text-container {
    position: relative;
    &--arrow {
      display: none;
    }
  }

  @media screen and (min-width: $breakpoint-laptop-size) {
    padding: 1rem 3rem;
    box-shadow: none;
    cursor: pointer;
    &__text-container {
      display: flex;
      align-items: center;
      flex: 1 0 auto;
      &--arrow{
        display: block;
        transform: rotateY(180deg);
        margin-left: auto;
      }
    }
  }
}