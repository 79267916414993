@import '../../styles/1.settings/settings';

.home__desktop-header {
  display: none;
  background: url('../../assets/vectors/background.svg') center center no-repeat;
}

@media screen and (min-width: $breakpoint-laptop-size) {
  %layout-card {
    width: 90vw;
    margin: 0 auto;
    background: $color-background;
    box-shadow: 0 8px 24px rgba(130, 130, 130, 0.25);
    border-radius: 8px;
    transform: translateY(-50px);
    padding: 2rem 0 0;
    height: clamp(400px, 70vh, 700px);
    overflow: hidden;
  }

  .tabs--desktop-helper {
    .tabs,
    .pivots-page__body,
    .order-pivots-page__body,
    .order-list-page__body,
    .order-products-page__body,
    .app-layout--with-cart {
      @extend %layout-card;
    }
    .home-header__top-container {
      display: none;
    }
    .home-page-body,
    .order-products-page__body,
    .order-pivots-page__body,
    .order-list-page__body {
      height: clamp(300px, calc(70vh - 75px), 640px);
      overflow-y: auto;
    }
    
  }
  .home__desktop-header {
    background-color: $color-primary;
    width: 100vw;
    padding: 2rem 5vw;
    min-height: 260px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-size: cover;
    .desktop-header__bg-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: auto;
    }
    .desktop-header__top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .desktop-header__links {
        display: flex;
        .link {
          z-index: 1;
          width: auto;
          align-items: flex-start;
        }
      }

    }
    .desktop-header__bottom {
      margin-top: auto;
      margin-bottom: 2.5rem;
      color: $color-secondary;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: $font-family-semibold;
      font-size: 24px;
      line-height: 1;
      .desktop-header__harvest {
        display: flex;
        align-items: center;
      }
    }
    nav {
      display: flex;
      height: 36px;
      a.link {
        color: $color-secondary;
        margin-left: 1rem;
        padding: 0 0.75rem;
        &:first-of-type {
          margin-left: 2rem;
        }
        img {
          display: none;
        }
        &.tab-link-active {
          font-family: $font-family-bold;
          border-bottom: 2px solid $color-secondary;
        }
      }
    }
  }
}
