@import '../../styles/1.settings/settings';

.reports-page {
  padding-top: 1rem;
  &-body {
    padding: 1rem $layout-padding 0;
  }
  &__info {
    margin-top: 8px;
    margin-bottom: 24px;
    font-family: $font-family-thin;
  }
  &__cards {
    display: grid;
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: $breakpoint-laptop-size) {
    &__cards {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }
  }
}