@import '../../styles/1.settings/settings';

.swiper {
  &-container {
    height: $swiper-height;
    margin-left: -#{$modal-padding} !important;
    width: calc(100% + #{$modal-padding}* 2);

    &:after {
      content: '';
      width: $swiper-height;
      height: $swiper-height;

      position: absolute;
      top: 0;
      left: calc(50% - #{$swiper-height}/ 2);

      background: rgba($color-secondary, 0.5);
      border: $border;
      border-radius: $border-radius;
    }
  }

  &-slide {
    font-size: $font-size-bold;
    text-align: center;
    color: $color-grey-one;
    opacity: 0.1;
    display: flex;

    &-active {
      font-size: 32px;
      color: $color-primary;
      opacity: 0.9;
    }

    &-prev,
    &-next {
      font-size: 24px;
      opacity: 0.2;
    }

    &-prev span {
      padding-right: 20px;
    }

    &-next span {
      padding-left: 20px;
    }
  }

  &__content {
    margin: auto;
    text-align: center;
  }
}
