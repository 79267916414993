@import '../../styles/1.settings/settings';

.my-checkbox {
  input {
    outline-color: $color-primary;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: $checkbox-size;
    z-index: 1;
  }

  &__name {
    display: block;
    user-select: none;
    margin-left: calc(#{$checkbox-size} + 12px);
    font-size: $font-size-regular;
    color: rgba($color-grey-one, 0.5);

    &:before {
      content: ' ';
      position: absolute;
      top: -8px;
      left: 0;
      height: $checkbox-size;
      width: $checkbox-size;
      background: $color-background;
      border: 3px solid rgba($color-grey-two, 0.5);
      box-sizing: border-box;
      border-radius: $border-radius-small;
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 8px;
      top: -5px;
      width: 8px;
      height: 13px;
      border: solid $color-background;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &--checked + &__name {
    &:before {
      background: $color-primary;
      border: none;
    }

    &:after {
      display: block;
    }
  }

  &__container {
    text-align: left;
    position: relative;
  }
}
