@import "../1.settings/settings";

.hidden {
  display: none;
}

.desktop-only {
  display: none;
  @media screen and (min-width: $breakpoint-laptop-size) {
    display: flex;
  }
}

.page-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 500px;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-background;
  z-index: 9999;
  .loader {
    width: 50px;
    height: 50px;
    border-width: 8px;
  }
}

@media screen and (min-width: $breakpoint-laptop-size) {
  %list-item--desktop {
    background: $color-background;
    border: 1px solid rgba(130, 130, 130, 0.25);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.25rem 3rem;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  %list-header--desktop {
    display: flex !important;
    padding: 0 3rem;
    p {
      text-transform: uppercase;
      color: $color-primary;
      font-size: 0.75rem;
      opacity: 0.6;
      font-weight: 700;
      width: 49%;
    }
  }
}
