@import '../../styles/1.settings/settings';

.empty-screen {
  width: 100%;
  padding: $layout-padding 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    margin-bottom: 3rem;
  }

  &__title {
    margin-bottom: 1rem;
    font-family: $font-family-bold;
    font-size: $font-size-bold;
    line-height: $line-height-bold;
    text-align: center;
  }

  &__body {
    font-family: $font-family-regular;
    font-size: $font-size-regular;
    line-height: $line-height-regular;
    text-align: center;
  }
}
