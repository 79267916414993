$layout-padding: 2rem;
$internal-padding: 1rem;

$input-margin: 16px auto;

$box-shadow: 0px 0.5rem 24px rgba($color-shadow, 0.25);

$border: 1px solid rgba($color-grey-two, 0.25);

$border-radius: 0.5rem;
$border-radius-small: 0.25rem;
$border-radius-large: 1.5rem;

$border-focus: 1px solid $color-primary;

$border-error: 1px solid $color-error;

$input-height: 3rem;

$checkbox-size: 24px;

$card-padding: 1rem;

$cart-tab-height: 60px;
$cart-header-height: 84px;
$cart-header-icon-size: 24px;
$cart-margin-top: 48px;
$cart-body-border-height: 20px;
$cart-content-height: calc(100vh - #{$cart-margin-top} - #{$cart-tab-height});
$cart-content-body-height: calc(
  #{$cart-content-height} - #{$cart-header-height} + #{$cart-body-border-height}
);
$cart-list-margin-bottom: 48px;
$cart-list-height: calc(
  #{$cart-content-body-height} - #{$layout-padding}* 2 - #{$input-height} - #{$cart-list-margin-bottom}
);

$cart-empty-margin-bottom: 16px;
$cart-empty-illustration-height: 108px;
$cart-empty-header-height: 69px;
$cart-empty-height: calc(
  #{$cart-content-body-height} - #{$layout-padding}* 2 - #{$cart-empty-margin-bottom} - #{$cart-empty-illustration-height} -
    #{$cart-empty-header-height}
);

$default-max-width: 600px;

$modal-margin: 32px;
$modal-padding: 24px;
$modal-border-radius: 24px;

$margin-spacing: 0.5rem;

$swiper-height: 72px;

$welcome-layout-width: 700px;
$welcome-layout-height: 600px;

$breakpoint-laptop-size: 1024px;
