@import '../../styles/1.settings/_settings.scss';

.pivots-page {
  &__header {
    padding: 1.5rem $layout-padding 0;

    &__title,
    &__subtitle {
      color: $color-secondary;
      margin: 0;
    }

    &__subtitle {
      margin-top: 4px;
      font-family: $font-family-thin;
      font-size: $font-size-thin;
      line-height: $font-size-thin;
    }
  }

  &__body {
    padding: 0 $layout-padding;
  }
  .container-header {
    display: none;
  }
    @media screen and (min-width: $breakpoint-laptop-size) {
      .container-header {
        display: flex;
      }
      .card-list__container {
        padding: 2.25rem 2rem;
        flex-wrap: wrap;
      }
    }

}
