label {
  font-family: $font-family-bold;
  font-size: $font-size-bold;
  line-height: $line-height-bold;
  display: block;
}

p {
  font-family: $font-family-regular;
  font-size: $font-size-regular;
  line-height: $line-height-regular;
  color: $color-text-main;
}
