h1 {
  font-family: $font-family-bold;
  font-size: $font-size-bold;
  line-height: $line-height-bold;
}

h2 {
  font-family: $font-family-semibold;
  font-size: $font-size-semibold;
  line-height: $line-height-semibold;
}

h3 {
  font-family: $font-family-regular;
  font-size: $font-size-regular;
  line-height: $line-height-regular;
}

h4 {
  font-family: $font-family-regular;
  font-size: $font-size-thin;
  line-height: $line-height-thin;
}

h5 {
  font-family: $font-family-regular;
  font-size: $font-size-extra-thin;
  line-height: $line-height-extra-thin;
}
