@mixin import-font($font) {
  $fontTypes: '', 'Black', 'Bold', 'ExtraBold', 'Heavy', 'Italic', 'Light', 'Medium', 'Regular',
    'Semibold', 'Thin', 'UltraLight';

  @each $fontType in $fontTypes {
    @font-face {
      font-family: #{$font}#{$fontType};
      @if $fontType == '' {
        src: url('../fonts/#{$font}/#{$font}.otf');
      } @else {
        src: url('../fonts/#{$font}/#{$font}-#{$fontType}.otf');
      }
    }
  }
}
