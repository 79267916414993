@import '../../styles/1.settings/settings';

.loader {
  border: 3px solid rgba($color-primary, 0.2);
  border-top-color: $color-primary;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  animation: spin 1s linear infinite;

  &--white {
    margin: auto;
    border: 3px solid rgba($color-grey-one, 0.2);
    border-top-color: $color-light;
    width: 30px;
    height: 30px;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
