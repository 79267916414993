@import '../../../styles/1.settings/_settings.scss';

.cart-empty {
  text-align: center;

  &__illustration {
    margin: $layout-padding;
    height: $cart-empty-illustration-height;
  }

  .header {
    text-align: center;
    height: $cart-empty-height;
    margin-bottom: $cart-empty-margin-bottom;
    border-bottom: $border;
    opacity: 0.5;

    &__content {
      max-width: 200px;
      margin: 16px auto;
    }
  }
}
