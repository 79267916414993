@import '../../../styles/1.settings/settings';

@media screen and (min-width: $breakpoint-laptop-size) {
  .order-products-page > .page-content {
    overflow-x: hidden;
  }
  .order-products-page {
    .navbar {
      display: none;
    }
  }
}
