@import '../../../../styles/1.settings/_settings.scss';

.form-line {
  width: 100%;
  margin: 24px 0;

  position: relative;

  &__title {
    display: flex;
    justify-content: space-between;
  } 

  &__name, 
  &__quantity {
    font-family: $font-family-bold;
    font-size: $font-size-bold;
    line-height: $line-height-bold;
    margin: auto 0;
  }

  &__name {
    color: $color-text-main;
  }

  &__quantity {
    color: $color-primary;
  }

  &__description {
    margin-top: 4px;
  }
}