@import '../../styles/1.settings/settings';

.card-list {
  &__container {
    display: flex;
    width: 100%;
    list-style: none;
    flex-wrap: wrap;

    @media (max-width: $breakpoint-laptop-size) {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: $border;
    border-radius: $border-radius;

    box-shadow: $box-shadow;

    margin-bottom: 1rem;
    div[role='button'] {
      padding: 20px 1rem;
      width: 100%;
    }

    &__name {
      color: $color-text-main;
      margin-bottom: 0.5rem;
    }

    &__info {
      font-family: $font-family-regular;
      font-size: $font-size-thin;
      line-height: $line-height-thin;
      color: $color-link;
      margin: 0;
    }
  }
  @media screen and (min-width: $breakpoint-laptop-size) {
    &__item {
      width: 200px;
      padding: 0;
      margin-right: 2rem;
      .item__text-container {
        cursor: pointer;
        width: 100%;
        padding: 1.25rem 1rem;
      }
    }
  }
}
