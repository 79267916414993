@import '../../styles/1.settings/settings';

.filter-modal {
  &__type {
    text-align: left;
    margin-bottom: 28px;

    &:last-of-type {
      margin-bottom: 64px;
    }
  }

  &__container {
    list-style: none;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    &__title {
      font-family: $font-family-bold;
    }
  }
}
