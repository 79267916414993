@import '../../styles/1.settings/settings';
@import '../../styles/5.trumps/classes';

.order-product-list {
  &__container {
    list-style: none;
    border-top: $border;
  }

  &__searchbar {
    width: calc(100% - #{$layout-padding}* 2);
    padding-left: 1.5rem;
    background-color: $color-secondary;
    border-radius: $border-radius;
    position: relative;
    margin: 0 $layout-padding;

    .item-inner {
      background-color: $color-secondary;
      border-radius: $border-radius;

      .item-input-wrap {
        width: 100%;
      }
    }

    .input-primary input {
      width: 100%;
      background-color: $color-secondary;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      background-image: url(../../assets/vectors/mag-glass.svg);
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0;

    &__number-request {
      margin-top: 0;
      margin-bottom: 3px;
    }

    &__name {
      font-family: $font-family-bold;
      font-size: $font-size-bold;
      line-height: $line-height-bold;
      color: $color-text-main;
      margin: auto 0;
    }

    &__arrow {
      transform: rotateY(180deg);
    }

    &__date {
      margin: 0;
      margin-top: 8px;

      color: rgba($color-error, 0.9);

      &--with,
      &--request {
        color: rgba($color-primary, 0.9);
      }
    }

    &__status {
      color: $color-primary;
      font-weight: bold;
      margin-bottom: 0;
    }

    &:not(:last-of-type) {
      border-bottom: $border;
    }
  }
  @media screen and (min-width: $breakpoint-laptop-size) {
    &__container {
      border-top: none;
    }
    &__item {
      @extend %list-item--desktop;
      padding: 1.25rem 3rem;
      cursor: pointer;
    }
  }
}
