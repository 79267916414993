@import '../../styles/1.settings/_settings.scss';

.content {
  padding: $layout-padding;
  min-height: 100vh;
  text-align: center;
  background-color: $color-background;

  &--blue {
    background-color: $color-primary;
  }
}
