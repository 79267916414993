@import '../../../../../styles/1.settings/settings';

.technician-profile-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__header {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba($color-grey-two, 0.25);
    margin-bottom: 32px;

    &__title {
      font-size: 18px;
      color: $color-grey-one;
      font-family: $font-family-bold;
    }
  }

  &__wrapper {
    padding: 0 60px;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;

      &__title {
        font-size: 18px;
        color: $color-grey-one;
        font-family: $font-family-bold;
        margin-bottom: 44px;
      }

      &__searchbar {
        width: 100%;
        padding-left: 1.5rem;
        background-color: $color-secondary;
        border-radius: $border-radius;
        position: relative;
        margin-bottom: 20px;

        .item-inner {
          background-color: $color-secondary;
          border-radius: $border-radius;

          .item-input-wrap {
            width: 100%;
          }
        }

        .input-primary input {
          width: 100%;
          background-color: $color-secondary;
        }

        &:before {
          content: '';
          display: inline-block;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 50%;
          left: 8px;
          transform: translateY(-50%);
          background-image: url(../../../../../assets/vectors/mag-glass.svg);
        }
      }

      &__container {
        max-height: 220px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        height: 220px;
        border: 1px solid #cdcfd0;
        border-radius: 8px;
        padding: 19px;
        overflow-y: auto;

        &__text {
          color: $color-grey-one;
          font-size: 14px;
          line-height: 16px;
          opacity: 0.5;
          margin-bottom: 14px;
          font-family: $font-family-regular;

          &__input {
            margin-right: 10px;
          }
        }
      }
    }

    &__footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
