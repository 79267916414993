@import '../../styles/1.settings/settings';

.layout-order-page {
  &__content {
    list-style: none;
    margin-top: 2rem;
    &--title{ 
      display: none;
    }
  }

  &__header {
    margin: 0;
    display: flex;
    column-gap: 1rem;
    gap: 1rem;
  }

  &__searchbar {
    width: 100%;
    padding-left: 1.5rem;
    background-color: $color-secondary;
    border-radius: $border-radius;
    position: relative;

    .item-inner {
      background-color: $color-secondary;
      border-radius: $border-radius;

      .item-input-wrap {
        width: 100%;
      }
    }

    .input-primary {
      margin: 0;

      input {
        width: 100%;
        background-color: $color-secondary;
      }
    }

    &:before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      background-image: url(../../assets/vectors/mag-glass.svg);
    }
  }

  &__filter {
    color: $color-primary;
    background-color: transparent;
    border: none;
    width: auto;
    cursor: pointer;
    font-size: 1rem;
  }
  @media screen and (min-width: $breakpoint-laptop-size) {
    &__content {
      &--title{ 
        display: block;
        font-family: $font-family-semibold;
        font-size: 24px;
        margin-bottom: 1rem;
      }
    }
    &__header {
      margin: $input-margin;
    }
  }
}