@import '../../styles/1.settings/settings';

.order-pivots-page__body,
.order-list-page__body,
.order-products-page__body {
  padding: 0rem $layout-padding !important;

  @media screen and (min-width: $breakpoint-laptop-size) {
    padding: 1rem $layout-padding 0 !important;
  }
}