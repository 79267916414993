@import '../../../../styles/1.settings/settings';
@import '../../../../styles/5.trumps/classes';

.page-type-order {
  position: relative;
  &--value, 
  &--name, 
  &--aplication-date{
    margin:0 0 0.5rem 0;
  }
  &--value,
  &--aplication-date {
    font-family: $font-family-regular;
    font-size: $font-size-thin;
    line-height: $line-height-thin;
  }
  &--value{
    color: $color-primary;
  }
  &--aplication-date{
    color: $color-error;
  }
  .status{
    color: $color-primary;
    font-family: $font-family-bold;
  }

  &--cancel-icon {
    width: 1rem;
  } 
  
  @media screen and (min-width: $breakpoint-laptop-size) {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    p {
      margin: 0;
    }
    &--value {
      font-size: $font-size-bold;
      line-height: $line-height-bold;
      color: $color-grey-one;
    }
    &--aplication-date{
      font-size: $font-size-semibold;
      line-height: $line-height-semibold;
    }
    
    &--value, &--name {
      width: 25%;
    }
    &--cancel-icon {
      margin-left: auto;
      margin-right: 0.5rem;
    }
  }
}