@import '../../../styles/1.settings/_settings.scss';

.order-application-page {
  &__header {
    padding: 1.5rem $layout-padding 0;

    &__title,
    &__days {
      color: $color-secondary;
      margin: 0;
    }

    &__days {
      margin-top: 4px;
      font-family: $font-family-thin;
      font-size: $font-size-thin;
      line-height: $font-size-thin;
    }
  }

  &__body {
    padding: 0 $layout-padding;

    .button-primary.button-fill {
      margin: 24px auto;
    }
  }

  &__date {
    margin: 0 auto;
  }

  &__error-message {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: rgba($color-error, 0.25);
    padding: 8px 16px;

    p {
      font-size: 12px;
      line-height: 12px;
      color: $color-error;
    }
  }

  &__warning-message {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: rgba($color-pending, 0.25);
    padding: 8px 16px;

    p {
      font-size: 12px;
      line-height: 12px;
      color: $color-pending;
    }
  }

  @media screen and (min-width: $breakpoint-laptop-size) {
    &.page {
      width: 100vw;
      height: 100vh;
      background-color: rgba(130, 130, 130, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      .page-content {
        min-width: 650px;
        width: 50vw;
        height: 600px;
        background-color: $color-background;
        .app-layout {
          padding: 0 4rem;
        }
        .default-header {
          background: $color-background;
          display: block;
          text-align: center;
          .order-application-page__header {
            width: 100%;
          }
          h1,
          p {
            color: $color-grey-one;
          }
        }
        .navbar {
          background-color: $color-background !important;
          padding: 0;
          height: 24px;
          .navbar__center.navbar__title {
            display: none;
          }
        }
        .header__content {
          max-width: none;
          margin-top: 1rem;
        }
        .content {
          min-height: auto;
          padding: 0;
        }
        .footer {
          position: relative;
        }
      }
    }
  }
}
