@import '../../styles/1.settings/settings';

.change-password-page {
  &__content {
    &__error {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
      border-radius: 4px;
      background-color: rgba($color-error, 0.25);
      padding: 8px 16px;

      p {
        font-size: 12px;
        line-height: 12px;
        color: $color-error;
      }
    }
  }
}
