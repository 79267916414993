@import '../../styles/1.settings/_settings.scss';
.header {
  $parent: &;
  color: $color-grey-one;
  text-align: left;
  max-width: 400px;
  margin: auto;
  &__title {
    font-size: $font-size-bold;
    font-family: $font-family-bold;
    font-weight: bold;
    line-height: 19px;
    opacity: 0.9;
  }
  &__content {
    font-family: $font-family-thin;
    font-size: 14px;
    line-height: 17px;
    margin-top: 8px;
    margin-bottom: 24px;
    opacity: 0.9;
  }
  &--centered {
    text-align: center;
    #{$parent}__content {
      max-width: 300px;
      margin: $layout-padding auto;
    }
  }
  &--white {
    #{$parent}__title {
      color: $color-secondary;
    }

    #{$parent}__content {
      color: $color-secondary;
    }
  }
}
