@import '../../styles/1.settings/settings';

$tabbar-width: 280px;
$header-height: 64px;
.tabs {
  --f7-toolbar-height: 60px;
  .toolbar {
    position: fixed;
    bottom: 0;
    &.tabbar {
      .toolbar-inner {
        padding-left: 16px;
        padding-right: 16px;
        background-color: $color-secondary;
      }
      a.link {
        text-transform: none;
        color: $color-primary;
        border-top: 2px solid transparent;
        img {
          margin: 4px;
          margin-top: 8px;
        }
        span {
          font-size: 12px;
          margin-bottom: 8px;
        }
        &.tab-link-active {
          border-top-color: $color-primary;
        }
      }
    }
    &.tabs-hidden {
      .tabbar {
        display: none;
      }
    }
    @media screen and (min-width: $breakpoint-laptop-size) {
      display: none;
    }
  }
  .tab {
    padding-bottom: 0;

    &-active {
      z-index: 10;
    }
    @media screen and (max-width: $breakpoint-laptop-size) {
      height: calc(
      100vh - var(--f7-page-toolbar-bottom-offset, 0px) + var(--f7-safe-area-bottom) +
        var(--f7-page-content-extra-padding-bottom, 0px)
      );
    }
  }
}

.home-page {
  &-body {
    padding: 1rem $layout-padding 0;
  }

  &__info {
    margin-top: 8px;
    margin-bottom: 24px;
    font-family: $font-family-thin;
  }
}
