@import '../../styles/1.settings/settings';

.app-layout {
  &--with-cart {
    padding-bottom: calc(1em + #{$cart-tab-height});
    @media screen and (min-width: $breakpoint-laptop-size) {
      padding-bottom: 0;
    }
  }
}
