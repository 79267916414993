@import '../../styles/1.settings/_settings.scss';

a.button-secondary {
  --f7-button-height: 3rem;
  --f7-button-border-radius: $border-radius;
  --f7-button-font-size: #{$font-size-semibold};
  text-transform: none;
  border-radius: $border-radius-small;
  font-weight: bold;
  white-space: pre-wrap;
  max-width: $default-max-width;
  margin: auto;
  background: transparent;
  color: $color-primary;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-top: -8px;
  }

  &--blue {
    color: $color-secondary !important;
  }
}
