@import '../../styles/1.settings/_settings.scss';

.config-product-page {
  &__header {
    padding: 1.5rem $layout-padding 0;

    &__title,
    &__days {
      color: $color-secondary;
      margin: 0;
    }

    &__days {
      margin-top: 4px;
      font-family: $font-family-thin;
      font-size: $font-size-thin;
      line-height: $font-size-thin;
    }
  }

  &__body {
    padding: 0 $layout-padding;
    padding-bottom: 100px;

    .button-primary.button-fill {
      margin: 24px auto;
    }
  }

  &__item {
    width: 100%;
    margin: 24px 0;

    position: relative;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      font-family: $font-family-bold;
      font-size: $font-size-bold;
      line-height: $line-height-bold;
      margin: auto 0;
    }

    &__name {
      color: $color-text-main;
    }

    &__volume {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &__dosage {
      margin: 0;
      margin-right: 8px;
      min-width: 90px;
    }

    &__quantity {
      color: $color-primary;
    }

    &__description {
      margin-top: 4px;
    }

    .pivot-area {
      margin: 0 auto;

      &--content {
        &:after {
          content: ' ';
          position: absolute;
          top: calc(50% - 17px / 2);
          left: 1rem;
          font-family: $font-family-thin !important;
          font-size: 14px !important;
          line-height: 17px !important;
          color: rgba($color-grey-one, 0.5) !important;
          @media screen and (min-width: $breakpoint-laptop-size) {
            top: 53%;
          }
        }

        @for $i from 0 to 7 {
          &-w#{$i}:after {
            left: calc(1rem + #{($i + 1) * 9}px);
          }
        }
      }
    }
  }
}
