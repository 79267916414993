*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

body {
  background-color: $color-background;
}

html,
body {
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
  height: 100% !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-text-main;
  clear: both;
  margin: 0;
}

body,
button,
input,
select,
optgroup,
textarea {
  color: $color-text-main;
  font-family: $font-family-main;
  font-size: $font-size-main;
  line-height: $line-height-main;
}

div.router-transition {
  .page-current {
    pointer-events: all;
  }
}

.preloader-backdrop {
  opacity: 1;
  background: rgba(0, 0, 0, 0.6);
}

.preloader-modal {
  --f7-preloader-modal-bg-color: transparent;
}

.dialog {
  --f7-dialog-border-radius: 1rem;
}

.row > .col,
.row > [class*='col-'] {
  height: 100%;
}

.link {
  width: 100%;
}
