@import '../../styles/1.settings/_settings.scss';

.button-primary {
  &.button-fill {
    --f7-button-height: #{$input-height};
    --f7-button-border-radius: #{$border-radius-small};
    --f7-button-font-size: #{$font-size-semibold};
    --f7-button-line-height: #{$line-height-semibold};
    display: block;
    text-transform: none;
    border-radius: $border-radius-small;
    font-family: $font-family-bold;
    font-weight: bold;
    white-space: pre-wrap;
    max-width: $default-max-width;
    margin: auto;
    margin-top: 1.5rem;
    background: $color-primary;
    color: $color-text-light;
    width: 100%;
  }

  &--white {
    background-color: $color-secondary !important;
    color: $color-primary !important;
  }
}
