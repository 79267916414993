@import '../../styles/1.settings/settings';

.cart {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 998;

  &__tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: $cart-tab-height;
    padding: 0.75rem $layout-padding 1rem $layout-padding;
    position: relative;

    border-top-left-radius: $border-radius-large;
    border-top-right-radius: $border-radius-large;
    background-color: $color-primary;

    transition: all $cart-animation-time;

    &__quantity-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      border: $border;
      margin-right: 0.5rem;

      background-color: $color-secondary;
      border-radius: $border-radius;

      animation: disappear $cart-animation-time forwards;

      &--close {
        animation: showUp $cart-animation-time forwards;
      }
    }

    &__quantity {
      color: $color-link;
    }

    &__text {
      flex-grow: 3;

      color: $color-secondary;

      animation: disappear $cart-animation-time forwards;

      &--close {
        animation: showUp $cart-animation-time forwards;
      }
    }

    &__icon {
      position: absolute;
      right: 2rem;
      top: calc(50% - #{$cart-header-icon-size}/ 2);

      transition: all $cart-animation-time;
    }

    &--open &__icon {
      right: calc(50% - #{$cart-header-icon-size}/ 2);
    }
  }

  &__content {
    height: $cart-content-height;
    visibility: visible;

    transition: height $cart-animation-time;

    &--close {
      visibility: hidden;
      transition: height $cart-animation-time, visibility 0s $cart-animation-time;
      height: 0;
    }

    &__header {
      height: $cart-header-height;
      padding: 0 $layout-padding 3rem $layout-padding;

      background-color: $color-primary;

      &__nucleus-harvest {
        margin: 0;
        margin-bottom: 4px;

        font-family: $font-family-regular;
        font-size: $font-size-thin;
        line-height: $line-height-thin;
        color: $color-secondary;
      }

      &__farm {
        font-family: $font-family-bold;
        font-size: $font-size-bold;
        line-height: $line-height-bold;
        color: $color-secondary;
      }
    }

    &__body {
      position: relative;
      min-height: $cart-content-body-height;

      padding: $layout-padding 0;
      transform: translateY(-#{$cart-body-border-height});

      background-color: $color-background;
      border-top-left-radius: $border-radius-large;
      border-top-right-radius: $border-radius-large;

      &__list {
        height: $cart-list-height;
        margin-bottom: $cart-list-margin-bottom;
        padding: 0 $layout-padding;
        overflow: auto;

        &--empty {
          height: 100%;
        }
      }

      &__close {
        text-align: center;
        color: $color-primary;
        margin: 0;
        cursor: pointer;
      }

      &__finish {
        margin: 0 $layout-padding !important;
        width: calc(100% - #{$layout-padding}* 2) !important;
        max-width: none !important;
      }
    }
  }

  &__background {
    height: 100vh;
    min-width: 100vw;

    position: absolute;
    top: 0;
    left: 0;

    background: $color-background--modal;
    opacity: 0.4;

    box-shadow: $box-shadow;

    z-index: 997;
  }

  @media screen and (min-width: $breakpoint-laptop-size) {
    right: 0;
    height: 100vh;
    width: 375px;
    transform: translateX(375px);
    transition: transform 300ms ease-in-out;
    &--desktop-open {
      transform: translateX(0);
    }
    &__background {
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    }

    &__content {
      height: 100%;
      padding: 2rem 0 1rem;
      background: $color-background;
      display: flex;
      flex-direction: column;
    }
    &__content__header {
      background: $color-background;
      padding: 1.5rem 1.5rem 0;
      position: relative;
      .header__button-close {
        border: none;
        background: transparent;
        cursor: pointer;
        position: absolute;
        width: 24px;
        top: 0;
        right: 1.5rem;
      }
      h1 {
        border-bottom: 1px solid rgba(130, 130, 130, 0.25);
        padding-bottom: 1.5rem;
      }
      > * {
        color: $color-grey-one;
      }
    }
    &__tab {
      display: none;
    }
    &__content__body {
      flex: 1 0 auto;
      transform: translateY(0);
      border-radius: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: transparent;
      padding: 1rem 0;
    }
  }
}
