@import '../../styles/1.settings/settings';
@import '../../styles/5.trumps/classes';

.farm-list {
  &__list-header--only-desktop {
    display: none;
  }
  &__container {
    list-style: none;
  }

  &__searchbar {
    width: 100%;
    padding-left: 1.5rem;
    background-color: $color-secondary;
    border-radius: $border-radius;
    position: relative;

    .item-inner {
      background-color: $color-secondary;
      border-radius: $border-radius;

      .item-input-wrap {
        width: 100%;
      }
    }

    .input-primary input {
      width: 100%;
      background-color: $color-secondary;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      background-image: url(../../assets/vectors/mag-glass.svg);
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: $internal-padding 0;

    &:first-child {
      padding-top: 0;
    }

    &:not(:last-of-type) {
      border-bottom: $border;
    }
    @media screen and (min-width: $breakpoint-laptop-size) {
      @extend %list-item--desktop;
      padding: 1.25rem 3rem;
      &:first-child {
        padding-top: 1.25rem;
      }
    }
  }
  @media screen and (min-width: $breakpoint-laptop-size) {
    &__list-header--only-desktop {
      @extend %list-header--desktop;
    }
  }
}