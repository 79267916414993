@import '../../styles/1.settings/settings';

.home-header {
  display: flex;
  flex-direction: column;
  background-color: $color-background;

  &--offline {
    border-bottom: none;
  }

  &__top-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: $layout-padding $layout-padding 0;
  }

  &__bottom-container {
    overflow-x: auto;
  }
  ::-webkit-scrollbar {
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: $color-select;
    border-radius: 10px;
    visibility:hidden;
  }
  :hover::-webkit-scrollbar-thumb {
    visibility:visible;
  }
}
