@import '../../styles/1.settings/_settings.scss';

.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    @extend %font-bold;
    color: $color-background;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    line-height: 30px;
    a {
      @extend %font-bold;
      color: $color-background;
    }
    svg {
      margin-left: 1rem;
    }
  }
}
