@import '../../../styles/1.settings/_settings.scss';

.order-cancel-page {
  .app-layout {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .reason-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  .input-primary {
    max-width: 100%;

    .item-input-wrap {
      background-color: $color-secondary;
      border-radius: $border-radius;
      width: 100%;
    }

    input {
      width: 100%;
      background-color: $color-secondary;
    }
  }

  &__header {
    padding: 0 $layout-padding;

    &__title,
    &__subtitle {
      color: $color-secondary;
      margin: 0;
    }

    &__subtitle {
      margin-top: 4px;
      font-family: $font-family-thin;
      font-size: $font-size-thin;
      line-height: $font-size-thin;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 $layout-padding 1.5rem;

    .highlight {
      color: $color-primary;
      font-weight: 700;
    }

    &__header {
      display: flex;
      flex-direction: column;

      h2 {
        color: $color-primary;
        text-align: center;
      }

      p {
        color: $color-grey-two;
        text-align: center;
        margin: unset;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      h2 {
        color: $color-primary;
        margin-bottom: 1rem;
      }

      .chemical-info {
        margin: unset;

        & + .chemical-info {
          margin-top: 0.5rem;
        }
      }
    }

    &__footer {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
      margin-top: auto;
      border-top: $border;
      padding-top: 1.5rem;

      &__button {
        max-width: unset !important;
        margin: unset !important;
        justify-self: end;

        & + &__button {
          margin-left: 1rem !important;
        }

        &--cancel {
          width: 100%;
        }
      }
    }

    .button-primary.button-fill {
      margin: 24px auto;
    }
  }

  &__error-message {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: rgba($color-error, 0.25);
    padding: 8px 16px;
    margin-top: 1rem;

    p {
      font-size: 12px;
      line-height: 12px;
      color: $color-error;
    }
  }

  &__error-warning {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: $color-link-visited;
    padding: 8px 16px;

    p {
      font-size: 12px;
      line-height: 12px;
      color: $color-light;
    }
  }

  @media screen and (min-width: $breakpoint-laptop-size) {
    &.page {
      width: 100vw;
      height: 100vh;
      background-color: rgba(130, 130, 130, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      .page-content {
        min-width: 650px;
        width: 50vw;
        height: 600px;
        overflow: hidden;
        background-color: $color-background;
        .app-layout {
          padding: 0 4rem;
          overflow: auto;
        }
        .default-header {
          background: $color-background;
          display: block;
          text-align: center;
          padding-bottom: 0;
          .order-cancel-page__header {
            width: 100%;
          }
          h1,
          p {
            color: $color-grey-one;
          }
        }
        .navbar {
          background-color: $color-background !important;
          padding: 0;
        }
        .header__content {
          max-width: none;
          margin-top: 1rem;
        }
        .content {
          min-height: auto;
          padding: 0;
        }
        .footer {
          position: relative;
        }

        .order-cancel-page {
          &__body {
            padding: 1.5rem $layout-padding;
            border-top: $border;

            &__footer {
              margin: 0.5rem -1.5rem 0;
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              grid-template-columns: 1fr 1fr;
            }
          }
        }
      }
    }
  }
}
