@import '../../styles/1.settings/_settings.scss';

.cart-resume {
  background: $color-background;
  color: $color-primary;
  @extend %font-semibold;
  font-size: $font-size-bold;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 48px;
  padding: 0 1rem;
  border-radius: 8px;
  cursor: pointer;
  &__number {
    background: $color-secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin: 0 0.5rem;
    border-radius: 8px;
  }
  &__text {}
}
