@import '../../styles/1.settings/settings';
@import '../../styles/5.trumps/classes';
.order-table {
  list-style: none;
  &__list-header--only-desktop{
    display: none;
  }
  
  @media screen and (min-width: $breakpoint-laptop-size) {
    &__list-header--only-desktop {
      display: flex;
      padding: 0 3rem 0.125rem 3rem;
      p {
        text-transform: uppercase;
        color: $color-primary;
        font-size: 0.75rem;
        opacity: 0.6;
        font-weight: 700;
        width: 45%;
      }
      .header-order {
        width: 24.5%
      }
      .header-normal {
        width: 45%
      }
    }
  }
}