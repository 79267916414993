@import '../../styles/1.settings/settings';

.dropdown {
  $parent: &;

  display: flex;
  align-items: center;

  padding: 6px 0.7rem;

  background-color: $color-background;
  border: $border;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  &__icon {
    height: 100%;
    path {
      stroke: $color-primary;
    }
  }

  &__selected {
    display: inline-block;

    margin-left: 0.75rem;
    margin-right: 0.75rem;

    color: $color-link;
  }

  &__separator {
    display: inline-block;

    width: 1px;
    height: 1.5rem;
    margin-right: 0.5rem;

    background-color: rgba($color-grey-two, 0.25);
  }

  &__content {
    display: none;
    width: calc(100% - (2 * #{$layout-padding}));
    padding: $internal-padding;
    border: $border;

    position: absolute;
    top: calc(10px + (2 * #{$layout-padding}));
    left: $layout-padding;

    box-shadow: $box-shadow;
    background-color: $color-background;
    border-radius: $border-radius;
    list-style: none;

    z-index: 10;
  }

  &__item {
    position: relative;
    color: $color-link;

    &--selected::before {
      content: '';
      display: inline-block;
      width: 2px;
      height: 2rem;
      background-color: $color-primary;
      position: absolute;
      top: 50%;
      left: calc(2px - #{$internal-padding});
      transform: translateY(-50%);
    }

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  &--is-open {
    #{$parent}__trigger {
      transform: rotateX(180deg);
    }

    #{$parent}__content {
      display: inline-block;
    }
  }
  @media screen and (min-width: $breakpoint-laptop-size) {
    background-color: transparent;
    border: none;
    box-shadow: none;
    display: inline-flex;
    position: relative;
    cursor: pointer;
    > * {
      color: $color-background;
    }
    &__item {
      &--selected::before {
        display: none;
      }
    }
    &__content {
      width: 100%;
      left: 1rem;
      top: 100%;
    }
    &__icon,
    &__trigger {
      path {
        stroke: $color-secondary;
      }
    }
    &__separator {
      display: none;
    }
    &__selected {
      display: flex;
      font-size: 1.125rem;
    }
  }
}
