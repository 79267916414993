$color-light: #ffffff;
$color-black: #000000;

$color-grey-one: #16171b;
$color-grey-two: #828282;

$color-primary: #0072ce;
$color-secondary: #e6e8ea;

$color-error: #eb5757;

$color-shadow: $color-grey-two;

$color-background: #f8f8f8;
$color-background--modal: $color-grey-one;

$color-text-main: rgba($color-grey-one, 0.9);
$color-text-light: $color-light;
$color-text-input-focus: $color-text-main;
$color-link: #127bf3;
$color-link-visited: purple;
$color-link-hover: midnightblue;
$color-pending: lightsalmon;
$color-avatar: #F2994A;

$color-select: #d9d9d9;
