@import '../../../styles/1.settings/settings';

.resale-stock-modal {
  &__quantity {
    font-size: $font-size-thin;
    margin: 0;
    &:not(:first-of-type) {
      margin-top: 32px;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__stocks {
    font-size: $font-size-extra-thin;
    margin-bottom: 8px;

    &__item {
      display: flex;
      justify-content: space-between;

      &__p {
        font-size: $font-size-extra-thin;
        margin: 0;
      }
    }
  }
  @media screen and (min-width: $breakpoint-laptop-size){
    &__quantity {
      font-size: $font-size-semibold;
    }
    &__stocks {
      &__item__p {
        font-size: $font-size-thin;
      }
    }
  }
}
