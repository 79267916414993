@import '../../../styles/1.settings/settings';

.user-profile {
  &__button {
    cursor: pointer;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
  }
  &__arrow {
    margin-right: 1rem;
    transition: transform 250ms ease-in-out;
    transform: rotate(0deg);
    path {
      stroke: $color-secondary;
    }
    &--inverted {
      transform: rotate(-180deg);
    }
  }
  &__avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
    padding-left: 2px;
    background-color: $color-avatar;
    color: $color-light;
    font-size: $font-size-regular;
    font-family: $font-family-thin;
    line-height: $line-height-regular;
  }
  &__name {
    color: $color-light;
    font-family: $font-family-bold;
  }

  &__container-button {
    background-color: $color-background;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    margin-top: 10px;

    &__logoff-menu {
      background: transparent;
      text-align: left;
      padding-left: 10px;
      position: relative;

      color: $color-primary;
      border: none;
      border-radius: 4px;
      margin-top: 0.5rem;
      cursor: pointer;
      height: 36px;
      width: 175px;
      transition: height 250ms ease, opacity 250ms ease-in;
      opacity: 1;

      &--hidden {
        height: 0;
        opacity: 0;
      }

      &:hover:before {
        content: ' ';
        width: 2px;
        height: 32px;

        position: absolute;
        border-radius: 4px;
        left: 5px;
        top: 0px;

        opacity: 1;
        background-color: $color-primary;
      }
    }
  }
}
