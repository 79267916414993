@import '../../styles/1.settings/_settings.scss';

@media screen and (min-width: $breakpoint-laptop-size) {

  .products-page {
    .navbar {
      display: none;
    }
    &__body {
      height: clamp(300px, calc(70vh - 75px), 640px);
      overflow-y: auto;
    }
    .page-content {
      overflow-x: hidden;
    }
  }
  .config-product-page.page {
    width: 100vw;
    height: 100vh;
    background-color: rgba(130, 130, 130, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .page-content {
      background-color: $color-background;
      min-width: 650px;
      width: 50vw;
      height: 75vh;
      overflow-x: hidden;
      overflow-y: auto;
      .default-header {
        display: block;
        background-color: transparent;
        text-align: center;
        border-bottom: 1px solid rgba(130, 130, 130, 0.25);
        h1, p {
          color: $color-grey-one;
        }
        .config-product-page__header {
          padding-top: 0;
        }
        .navbar {
          background-color: transparent !important;
          &__title{
           display: none;
          }
        }
      }
      .config-product-page__body {
        padding: 0 6rem;
      }
    }
  }
}
