@import '../../styles/1.settings/settings';

.select {
  $parent: &;

  border-radius: $border-radius;
  padding: 16px;
  border: $border;
  background-color: $color-secondary;

  margin: 16px auto;
  max-width: $default-max-width;

  height: 3rem;

  font-family: $font-family-thin;
  font-size: 14px;
  line-height: 17px;
  color: rgba($color-grey-one, 0.5);

  &:after {
    content: '';
    position: absolute;
    top: 12px;
    right: 40px;
    width: 1px;
    height: 24px;
    background: $color-grey-two;
    opacity: 0.25;
  }

  &__arrow {
    position: absolute;
    top: 21px;
    right: 16px;

    transition: transform 0.5s;
  }

  &__container {
    position: relative;
  }

  &__option {
    z-index: 1;
    position: absolute;
    top: 3rem;
    width: 100%;
    margin-top: 4px;

    border-radius: 0.6rem;
    border: $border-focus;
    background-color: $color-secondary;

    &s {
      margin: 0;
      padding: 8px 16px;

      font-family: $font-family-thin;
      font-size: 14px;
      line-height: 17px;
      color: rgba($color-grey-one, 0.5);

      &--selected {
        background-color: $color-primary;
        color: $color-light;
      }

      &:first-child {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }

  &--open {
    #{$parent}__arrow {
      transform: rotateX(-180deg);
    }
  }
}
