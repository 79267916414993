@import '../../styles/1.settings/settings';

.modal {
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  z-index: 1000;
  &__close {
    display: none;
  }

  &__background {
    height: 100vh;
    min-width: 100vw;

    position: absolute;
    top: 0;
    left: 0;

    background: $color-background--modal;
    opacity: 0.4;

    box-shadow: $box-shadow;

    z-index: 999;
  }

  &__content {
    width: calc(100% - #{$modal-margin}* 2);
    padding: $modal-padding;
    margin: 0 $modal-margin;
    max-height: calc(100% - #{$modal-margin}* 2);
    overflow: auto;

    position: relative;

    box-shadow: $box-shadow;
    border-radius: $modal-border-radius;
    background: $color-background;

    text-align: center;

    z-index: 1000;

    &__sign {
      margin-bottom: 8px;
    }
  }

  &__title {
    margin-bottom: 8px;
  }

  &__description {
    margin-bottom: 40px;

    &--swiper {
      margin-bottom: 16px;
    }

    &__name {
      font-weight: bold;
      font-family: $font-family-bold;
      color: $color-primary;
    }
  }
  @media screen and (min-width: $breakpoint-laptop-size) {
    &__content {
      min-width: 650px;
      width: 50vw;
      margin: 0 auto;
      padding: 3rem 6rem;
      position: relative;
    }
    &__close {
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      outline: none;
      border: none;
      background: transparent;
      top: 1.5rem;
      right: 2rem;
      cursor: pointer;
    }
  }
}
