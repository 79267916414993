@import '../../styles/1.settings/settings';

input[type=number].leftover-number {
  height: $swiper-height;
  width: $swiper-height;
  font-size: 32px;
  color: $color-primary;
  opacity: 0.9;
  background: rgba($color-secondary, 0.5);
  border: $border;
  border-radius: $border-radius;
  text-align: center;
  margin: 0 auto;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
