@import '../../styles/1.settings/_settings.scss';
@import '../../styles/2.tools/_tools.scss';

.forgot-password-email-sent-page {
  &__illustration {
    margin: $layout-padding;
  }
  .header {
    text-align: center;
    &__content {
      max-width: 200px;
      margin: $layout-padding auto;
    }
  }
}
