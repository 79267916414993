$font-family-bold: GilroyBold;
$font-size-bold: 18px;
$line-height-bold: 20px;
$font-family-semibold: GilroySemibold;
$font-size-semibold: 16px;
$line-height-semibold: 20px;
$font-family-regular: Gilroy;
$font-size-regular: 14px;
$line-height-regular: 17px;
$font-family-thin: GilroyLight;
$font-size-thin: 12px;
$line-height-thin: 12px;
$font-size-extra-thin: 10px;
$line-height-extra-thin: 10px;

$font-family-main: $font-family-regular;
$font-size-main: $font-size-bold;
$line-height-main: $line-height-bold;

%font-bold {
  font-family: $font-family-bold;
  font-size: $font-size-bold;
  line-height: $line-height-bold;
}

%font-semibold {
  font-family: $font-family-semibold;
  font-size: $font-size-semibold;
  line-height: $line-height-semibold;
}

%font-regular {
  font-family: $font-family-regular;
  font-size: $font-size-regular;
  line-height: $line-height-regular;
}

%font-thin {
  font-family: $font-family-thin;
  font-size: $font-size-thin;
  line-height: $line-height-thin;
}

%font-extra-thin {
  font-family: $font-family-thin;
  font-size: $font-size-extra-thin;
  line-height: $line-height-extra-thin;
}
